import React, { useState, useEffect, useRef } from 'react';
import { FaFacebook, FaInstagram, FaPhone, FaEnvelope } from 'react-icons/fa';
import './styles/SocialLayout.css';

const SocialLayout = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const containerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // GESTION DU HOVER SUR DESKTOP
  const handleMouseEnter = () => {
    if (!isMobile) {
      setIsHovered(true);
    }
  };
  const handleMouseLeave = () => {
    if (!isMobile) {
      setIsHovered(false);
    }
  };

  // GESTION DU CLIC SUR MOBILE
  const handleClickContainer = () => {
    if (isMobile) {
      setIsHovered((prev) => !prev);
    }
  };

  // FERMETURE AU CLIC EXTERNE
  useEffect(() => {
    if (!isMobile || !isHovered) return;

    const handleClickOutside = (e) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(e.target)
      ) {
        setIsHovered(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isMobile, isHovered]);

  // FERMETURE AU SCROLL EN MOBILE
  useEffect(() => {
    if (!isMobile || !isHovered) return;

    const handleScroll = () => {
      setIsHovered(false);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobile, isHovered]);

  return (
    <div
      ref={containerRef}
      className={`social-container ${isHovered ? 'hovered' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClickContainer}
    >
      <a
        href="https://www.instagram.com/lesenduitsdeugenie/"
        target="_blank"
        rel="noopener noreferrer"
        className="social-icon instagram"
      >
        <FaInstagram />
      </a>

      <a
        href="https://www.facebook.com/les.enduits.deugenie/"
        target="_blank"
        rel="noopener noreferrer"
        className="social-icon facebook"
      >
        <FaFacebook />
      </a>

      {/*
        On n’affiche TÉLÉPHONE + MAIL *que* pour la version mobile
        (isMobile === true)
      */}
      {isMobile && (
        <>
          <a
            href="tel:+33652220297"
            className="social-icon phone"
          >
            <FaPhone />
          </a>

          <a
            href="mailto:lesenduitsdeugenie@mailo.com"
            className="social-icon mail"
          >
            <FaEnvelope />
          </a>
        </>
      )}
    </div>
  );
};

export default SocialLayout;
