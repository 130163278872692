import React, { useState } from 'react';
import './styles/Service.css';
import ServiceModal from '../ServiceModal.js';

import enduitFinitionImage1 from '../../assets/apropos/chaux/10.webp';
import enduitFinitionImage2 from '../../assets/services/13.webp';
import betonCireImage1 from '../../assets/services/betonCire/1.webp';
import betonCireImage2 from '../../assets/services/betonCire/2.webp';
import badigeonsImage1 from '../../assets/apropos/badi/1.webp';
import badigeonsImage2 from '../../assets/apropos/badi/2.webp';
import decoMuraleImage1 from '../../assets/apropos/decoMural/1.webp';
import decoMuraleImage2 from '../../assets/services/12.webp';

function Service() {
  const [selectedServiceIndex, setSelectedServiceIndex] = useState(null);

  const services = [
    {
      title: 'Enduits de Finition',
      image: enduitFinitionImage1,
      backgroundImage: enduitFinitionImage2,
      description: 'Sublimez vos murs avec nos enduits de finition à base de chaux, terre et plâtre. Nos enduits sont non seulement esthétiques, mais également respirants et écologiques, apportant une touche d’authenticité à vos espaces intérieurs.',
      fullDescription: `Les enduits de finition sont utilisés depuis des millénaires, que ce soit dans l'Antiquité ou dans des projets modernes. 
      Ces enduits sont réalisés à base de matériaux naturels comme la chaux, l'argile ou le plâtre. Leur propriété respirante permet de réguler l'humidité dans les bâtiments tout en offrant une finition esthétique durable. 

      La finition peut être lisse ou texturée selon vos préférences, et les enduits peuvent être colorés grâce à des pigments naturels. Appréciés pour leur beauté brute et leur authenticité, ils trouvent leur place dans des rénovations patrimoniales ou des projets modernes à caractère écologique.`,
    },
    {
      title: 'Béton Ciré',
      image: betonCireImage1,
      backgroundImage: betonCireImage2,
      description: 'Pour un style contemporain et raffiné, optez pour le béton ciré. Adapté aussi bien aux sols qu’aux murs, il se distingue par sa résistance et son rendu esthétique. Nos bétons cirés sont disponibles dans une large gamme de couleurs et de finitions.',
      fullDescription: `Le béton ciré, d'origine industrielle, est un matériau qui a su se transformer pour s'imposer comme un choix de finition élégant et moderne. Ce revêtement lisse et durable est adapté aux sols, murs, et plans de travail.

      Très prisé dans les espaces contemporains, le béton ciré offre une résistance exceptionnelle à l'usure et à l'humidité, tout en étant facile à entretenir. Sa large gamme de finitions – du mat au brillant – et ses multiples couleurs en font une option idéale pour personnaliser votre intérieur. 
      
      En rénovation ou en construction neuve, le béton ciré est une solution esthétique et technique qui s’intègre parfaitement à différents styles d’architecture.`,
    },
    {
      title: 'Badigeons',
      image: badigeonsImage1,
      backgroundImage: badigeonsImage2,
      description: 'Offrez à vos murs une finition naturelle avec nos badigeons à base de chaux et de pigments naturels. Ce revêtement, en plus de sublimer vos façades et intérieurs, assure une excellente respirabilité des murs tout en leur offrant une protection durable et esthétique.',
      fullDescription: `Les badigeons sont utilisés depuis l'Antiquité pour leurs propriétés décoratives et protectrices. Ce revêtement à base de chaux naturelle est particulièrement apprécié pour sa capacité à laisser respirer les murs tout en apportant une finition douce et subtile.

      En extérieur comme en intérieur, les badigeons se déclinent en plusieurs nuances grâce à l'ajout de pigments naturels. Ils sont parfaits pour recréer l'ambiance des maisons provençales ou méditerranéennes. 

      Grâce à leur finition veloutée, les badigeons apportent une atmosphère chaleureuse et vivante à vos murs, tout en améliorant la qualité de l’air intérieur.`,
    },
    {
      title: 'Décoration Murale',
      image: decoMuraleImage1,
      backgroundImage: decoMuraleImage2,
      description: 'Nous proposons des solutions sur mesure pour vos murs. Avec des techniques artisanales comme le stuc, les badigeons ou les enduits à la chaux, nous transformons vos surfaces en de véritables tableaux vivants.',
      fullDescription: `La décoration murale ne se limite pas à une simple couche de peinture. Grâce à des techniques artisanales comme les enduits à la chaux, le stuc ou encore le sgrafitto, nous transformons vos surfaces en véritables œuvres d'art.

      Le sgrafitto, technique ancienne et raffinée, consiste à superposer plusieurs couches de couleurs différentes, puis à gratter délicatement pour révéler des motifs uniques. Il permet de créer des reliefs et des jeux de couleurs subtils, apportant caractère et élégance à vos murs.
      
      Nos techniques s’adaptent à tous les styles, ajoutant une touche d’authenticité et de personnalité à votre intérieur.`,
    },
    
  ];

  const openModal = (index) => {
    setSelectedServiceIndex(index);
  };

  const closeModal = () => {
    setSelectedServiceIndex(null);
  };

  const goToPreviousService = () => {
    setSelectedServiceIndex((prevIndex) => (prevIndex === 0 ? services.length - 1 : prevIndex - 1));
  };

  const goToNextService = () => {
    setSelectedServiceIndex((prevIndex) => (prevIndex === services.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="service-container">
      <h1>Nos Services</h1>
{/**       <p>
        Avec <em>Les Enduits d’Eugénie</em>, vous transformez vos murs et votre lieu de vie en véritables œuvres d’art en utilisant des matériaux naturels et des techniques artisanales. Découvrez ci-dessous nos principaux services :
  </p>**/}

      <div className="services-list">
        {services.map((service, index) => (
          <div
            className="service-item"
            key={index}
            onClick={() => openModal(index)}
          >
            <img src={service.image} alt={service.title} />
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

      {/* Modale pour les détails des services */}
      {selectedServiceIndex !== null && (
        <ServiceModal
          show={selectedServiceIndex !== null}
          onClose={closeModal}
          service={services[selectedServiceIndex]}
          onPrevious={goToPreviousService}
          onNext={goToNextService}
        />
      )}
    </div>
  );
}

export default Service;
