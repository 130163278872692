import React from 'react';
import logo from '../assets/logo2.webp';
import './styles/HeroSection.css';
import { Link } from 'react-router-dom';

function HeroSection({ isAside }) {
  return (
    <div className={`hero-container ${isAside ? 'aside' : ''}`}>
      <div className="hero-overlay">
        <div className="logocontainer">
          <Link to="/">
            <img
              src={logo}
              alt="Les Enduits d'Eugénie"
              className={`hero-logo ${isAside ? 'logo-aside' : ''}`}
            />
          </Link>
        </div>
        <h1>Donnez à vos espaces une finition exceptionnelle</h1>
        <p>Experte des enduits naturels & décoratifs</p> <p>Qualité, précision, durabilité</p>
        <Link to="/contact">
          <button className="cta-button">Demandez un devis gratuit</button>
        </Link>
        <div className='footer'>
        <h5><a href="tel:0652220297">06 52 22 02 97</a></h5>
        <h5><a href="mailto:contact@les-enduits-deugenie.fr">contact@les-enduits-deugenie.fr</a></h5>
        <h5><a href="https://www.google.com/maps/place/Les+Enduits+d%27Eugenie/data=!4m7!3m6!1s0x4e8e413637b89c63:0x934cfce86c46a298!8m2!3d43.7877091!4d4.1981143!16s%2Fg%2F11wtf3dklx!19sChIJY5y4NzZBjk4RmKJGbOj8TJM?authuser=0&hl=fr&rclk=1" target="_blank" rel="noopener noreferrer">Cévennes - Montpellier - Avignon</a></h5>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
