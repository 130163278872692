import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './styles/Navbar.css';
import logo from '../assets/logo2.webp';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const isHomePage = location.pathname === '/';

  // obtenir le nom de la page
  const getPageName = () => {
    switch (location.pathname) {
      case '/apropos':
        return 'À Propos';
      case '/services':
        return 'Services';
      case '/galerie':
        return 'Galerie';
      case '/contact':
        return 'Contact';
      case '/blog':
        return 'Blog';
      case '/':
      default:
        return "Les Enduits d'Eugénie";
    }
  };

  // fermer le menu lors d'un clic sur un lien
  const handleMenuClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav className={`navbar ${isHomePage ? 'is-homepage' : ''}`}>
      <div className="navbar-left">
        {isHomePage ? (
          <span className="navbar-logo-text">Les Enduits d'Eugénie</span>
        ) : (
          <>
            <Link to="/" className="navbar-logo-link">
              <img src={logo} alt="Les Enduits d'Eugénie" className="navbar-logo-image" />
            </Link>
            <span className="navbar-page-name">{getPageName()}</span>
          </>
        )}
      </div>

      {!isHomePage && (
        <Link to="/contact" className="navbar-cta-link">
          <button className="navbar-cta-button">Demandez un Devis</button>
        </Link>
      )}

      <div className="burger-menu" onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <span className={`burger-bar ${isMenuOpen ? 'open' : ''}`}></span>
        <span className={`burger-bar ${isMenuOpen ? 'open' : ''}`}></span>
        <span className={`burger-bar ${isMenuOpen ? 'open' : ''}`}></span>
      </div>

      <ul className={`navbar-menu ${isMenuOpen ? 'open' : ''}`}>
        <li onClick={handleMenuClick}>
          <Link to="/">Accueil</Link>
        </li>
        <li onClick={handleMenuClick}>
          <Link to="/apropos">À Propos</Link>
        </li>
        <li onClick={handleMenuClick}>
          <Link to="/services">Services</Link>
        </li>
        <li onClick={handleMenuClick}>
          <Link to="/galerie">Galerie</Link>
        </li>
        <li onClick={handleMenuClick}>
          <Link to="/contact">Contact</Link>
        </li>
        <li onClick={handleMenuClick}>
          <Link to="/blog">Blog</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
